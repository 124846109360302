import Main from "./components/Main";
import AutoPlaySilentVideo from "./components/AutoPlayVideo";
import background from "./videos/background.mp4";
import "./css/main.css";
import ilb from "./image/alb.png";

import React, { useState } from "react";

function App() {
  const [notificationClosed, setNotificationClosed] = useState(false);

  const handleNotificationClose = () => {
    setNotificationClosed(true);
  };
  return (
    <div className="App">
      <div className="video-container">
        <AutoPlaySilentVideo video={background} screenshot={ilb} />
        <div className="main-container">{<Main />}</div>
      </div>
    </div>
  );
}

export default App;
