import React, { useState, useEffect } from "react";

const CountdownTimer = ({ targetDate, onTimerEnd }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      const duration = new Date(difference);
      timeLeft = {
        days: String(duration.getUTCDate() - 1).padStart(2, "0"),
        hours: String(duration.getUTCHours()).padStart(2, "0"),
        minutes: String(duration.getUTCMinutes()).padStart(2, "0"),
        seconds: String(duration.getUTCSeconds()).padStart(2, "0"),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (Object.values(timeLeft).every((val) => parseInt(val) === 0)) {
      onTimerEnd();
    }
  }, [timeLeft, onTimerEnd]);

  return (
    <div>
      {Object.values(timeLeft).some((val) => parseInt(val) > 0) ? (
        <div className="timer-container">
          <span>{timeLeft.days}:</span>
          <span>{timeLeft.hours}:</span>
          <span>{timeLeft.minutes}:</span>
          <span>{timeLeft.seconds}</span>
        </div>
      ) : (
        <span>Событие уже началось!</span>
      )}
    </div>
  );
};

export default CountdownTimer;
