import MusicianCard from "./MusicianCard";
import React, { useState } from "react";
import CountdownTimer from "./Timer";

import data from "../data/musician_data.json";

const MusicianProfile = () => {
  const musician = data;
  const handleTimerEnd = () => {
    setTimerEnded(true);
  };
  const [timerEnded, setTimerEnded] = useState(false);

  return (
    <div>
      {!timerEnded && (
        <CountdownTimer
          targetDate="2024-05-10T00:00:00"
          onTimerEnd={handleTimerEnd}
        />
      )}
      {timerEnded && (
        <div>
          <MusicianCard name={musician.name} platforms={musician.platforms} />
        </div>
      )}
    </div>
  );
};

export default MusicianProfile;
