import React, { Component } from "react";

class BackgroundAudio extends Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
  }

  componentDidMount() {
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    this.playAudio();
  }

  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  }

  playAudio = () => {
    const audio = this.audioRef.current;
    if (audio.paused) {
      audio.play().catch((error) => {
        console.error("Failed to play audio:", error);
      });
    }
  };

  pauseAudio = () => {
    const audio = this.audioRef.current;
    if (!audio.paused) {
      audio.pause();
    }
  };

  handleVisibilityChange() {
    if (document.hidden) {
      this.pauseAudio();
    } else {
      this.playAudio();
    }
  }

  render() {
    return (
      <audio ref={this.audioRef} loop autoPlay>
        <source src={this.props.src} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    );
  }
}

export default BackgroundAudio;
