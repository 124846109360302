import React, { useState, useEffect } from "react";

const RandomText = ({ texts, audioFiles }) => {
  const [randomText, setRandomText] = useState("");
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [previousIndex, setPreviousIndex] = useState(-1);
  const [showingText, setShowingText] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [fadeOutInterval, setFadeOutInterval] = useState(null);

  useEffect(() => {
    if (
      !texts ||
      !audioFiles ||
      texts.length === 0 ||
      audioFiles.length === 0
    ) {
      return;
    }

    const getRandomText = () => {
      let newIndex;
      do {
        newIndex = Math.floor(Math.random() * texts.length);
      } while (newIndex === previousIndex);
      return { index: newIndex, text: texts[newIndex] };
    };

    const showNextText = () => {
      const { index, text } = getRandomText();
      setRandomText(text);

      const randomX = Math.random() * (window.innerWidth - 200); // Ширина элемента 200px
      let randomY;
      do {
        randomY = Math.random() * (window.innerHeight - 40); // Высота элемента 40px
      } while (
        randomY > window.innerHeight / 2 - 40 &&
        randomY < window.innerHeight / 2 + 40
      );
      setPosition({ x: randomX, y: randomY });

      setPreviousIndex(index);
      setShowingText(true);

      const textInterval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex >= text.length) {
            clearInterval(textInterval);
            setTimeout(() => {
              setRandomText("");
              setShowingText(false);
              setCurrentIndex(0);
            }, 25000); // Пауза между показами текстов 10 секунд после окончания показа текста
          }
          return nextIndex;
        });
      }, 250); // Задержка между отображением букв 100 мс
    };

    if (!showingText) {
      setTimeout(() => {
        showNextText();
      }, 2000); // Пауза между показами текстов 2 секундa после окончания предыдущего текста
    }

    return () => {
      if (currentAudio) {
        fadeOutAudio();
      }
    };
  }, [texts, audioFiles, previousIndex, showingText]);

  const fadeOutAudio = () => {
    if (!currentAudio) return;

    let volume = currentAudio.volume;
    const fadeOutInterval = setInterval(() => {
      volume -= 0.05;
      if (volume <= 0) {
        currentAudio.volume = 0;
        clearInterval(fadeOutInterval);
      } else {
        currentAudio.volume = volume;
      }
    }, 100); // Уменьшаем громкость каждые 100 мс
    setFadeOutInterval(fadeOutInterval);
  };

  const handleTextClick = () => {
    if (showingText && currentAudio) {
      currentAudio.play();
    }
  };

  return (
    <div
      className={`random-text ${randomText ? "show" : ""}`}
      style={{ top: position.y, left: position.x }}
    >
      <p className="text glitch" onClick={handleTextClick}>
        {randomText.slice(0, currentIndex)}
      </p>
      {showingText && (
        <audio
          src={audioFiles[previousIndex]}
          preload="auto"
          ref={(audio) => setCurrentAudio(audio)}
        ></audio>
      )}
    </div>
  );
};

export default RandomText;
