import React from "react";
import CountdownTimer from "../components/Timer";
import RandomText from "../components/RandomText";
import MusicianProfile from "../components/MusicianProfile";
import anomia from "../audio/аномия.wav";
import hochu from "../audio/хочу не хочу.wav";
import bespomochnost from "../audio/беспомощность.wav";
import protivno from "../audio/противно.wav";
import parnishka from "../audio/парнишка.wav";
import barabashka from "../audio/барабашка.wav";
import peregnoy from "../audio/когда я стану перегноем.wav";
import kto from "../audio/кто я.wav";
import smehlist from "../audio/смехлыст.wav";
import spasibo from "../audio/спасибо брат.wav";
import inogda from "../audio/иногда.wav";
import nichego from "../audio/ничего.wav";
import BackgroundMusic from "../components/BackgroundMusic";
import bk_music from "../audio/bk_audio.WAV";

class Main extends React.Component {
  render() {
    return (
      <div className="main-container">
        <MusicianProfile />
        <div className="notification-container">
          <RandomText
            texts={[
              "тебе никогда не вернуть себе себя.",
              "я боюсь, я боюсь, я боюсь, я боюсь!",
              "напрягаю большинство возможных мышц, чувствую беспомощность.",
              "я только могу лежать как плесень.",
              "мне всегда казалось, что все важное было до меня.",
              "ты не слышишь меня, но чувствуешь взгляд.",
              "когда я стану перегноем, и на мне вырастут цветы.",
              "кто я? я просто голос в голове.",
              "оставь меня в покое, оставь меня в покое.",
              "невыносимо гнусное проклятие - мне больше не с кем погулять теперь.",
              "проснуться утром, почистить зубы, больше не кажется настолько глупым.",
              "я не знаю, как сказать тебе о том, что чувствую сейчас.",
            ]}
            audioFiles={[
              anomia,
              hochu,
              bespomochnost,
              protivno,
              parnishka,
              barabashka,
              peregnoy,
              kto,
              smehlist,
              spasibo,
              inogda,
              nichego,
            ]}
          />
        </div>

        <BackgroundMusic src={bk_music} />
      </div>
    );
  }
}

export default Main;
