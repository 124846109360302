import React from "react";
import "../css/MusicianCard.css";

const MusicianCard = ({ name, image, platforms }) => {
  return (
    <div className="musician-card">
      <h2 className="musician-name">{name}</h2>
      <div className="platform-links">
        {platforms.map((platform, index) => (
          <a
            key={index}
            href={platform.url}
            className="platform-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={platform.icon}
              alt={platform.name}
              className="platform-icon"
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default MusicianCard;
