import React, { useRef, useEffect } from "react";
import "../css/cover.css";

export default function AutoPlaySilentVideo(props) {
  const videoRef = useRef(null);
  const imgRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    const imgElement = imgRef.current;

    // JavaScript код для включения видео
    videoElement
      .play()
      .then(() => {})
      .catch((error) => {
        videoElement.remove();
        imgElement.style.display = "block";
      });
  }, []);

  return (
    <>
      <video
        className={props.className}
        ref={videoRef}
        loop
        muted
        playsInline
        style={{ display: "block" }}
      >
        <source src={props.video} type="video/mp4" />
      </video>
      <img
        ref={imgRef}
        className={props.className}
        src={props.screenshot}
        alt="Video screenshot"
        style={{ display: "none" }}
      />
    </>
  );
}
